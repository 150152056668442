import { KeyboardEventHandler, useCallback, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Spinner,
  useMergeRefs,
} from '@chakra-ui/react'

import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'

import { useMutateFormSettings } from '../../mutations'
import { useAdminFormSettings } from '../../queries'

export const BotMDCleoSessionInput = (): JSX.Element => {
  const { data: settings, isLoading } = useAdminFormSettings()
  const { mutateFormBotMDSession } = useMutateFormSettings()
  const {
    register,
    formState: { errors, isValid },
    resetField,
    getValues,
  } = useForm<{ cleoSession: string }>({
    mode: 'onChange',
  })

  const handleUpdateSession = useCallback(() => {
    if (isLoading) return
    const nextSession = getValues('cleoSession')
    if (settings?.botmd.cleoSession === nextSession) return
    return mutateFormBotMDSession.mutate(nextSession, {
      onError: () => resetField('cleoSession'),
    })
  }, [
    getValues,
    isLoading,
    mutateFormBotMDSession,
    resetField,
    settings?.botmd.cleoSession,
  ])

  const handleCleoSessionInputBlur = useCallback(() => {
    if (!isValid) {
      return resetField('cleoSession')
    }
    return handleUpdateSession()
  }, [handleUpdateSession, isValid, resetField])

  const cleoSessionRegister = register('cleoSession', {
    onBlur: handleCleoSessionInputBlur,
    validate: {
      noWhitespace: (value: string) => {
        return (
          !(value || '').trim().match(/\s/) ||
          'Cleo Session must not contain whitespace'
        )
      },
    },
  })

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (isLoading || !settings) return
    resetField('cleoSession', { defaultValue: settings.botmd.cleoSession })
  }, [isLoading, resetField, settings])

  const mergedRefs = useMergeRefs(cleoSessionRegister.ref, inputRef)

  const handleSessionEnterKeyDown: KeyboardEventHandler = useCallback(
    (e) => {
      if (!isValid || e.key !== 'Enter') return
      return inputRef.current?.blur()
    },
    [isValid],
  )

  return (
    <FormControl
      isReadOnly={mutateFormBotMDSession.isLoading}
      isInvalid={!!errors.cleoSession}
    >
      <FormLabel description="Cleo session that used to retrieve data from BotMD for populating options in component.">
        Cleo Session
      </FormLabel>
      <Skeleton isLoaded={!isLoading}>
        <InputGroup>
          {mutateFormBotMDSession.isLoading ? (
            <InputRightElement pointerEvents="none">
              <Spinner />
            </InputRightElement>
          ) : null}
          <Input
            placeholder="Cleo session obtain from BotMD"
            onKeyDown={handleSessionEnterKeyDown}
            {...cleoSessionRegister}
            ref={mergedRefs}
          />
        </InputGroup>
      </Skeleton>
      <FormErrorMessage>{errors.cleoSession?.message}</FormErrorMessage>
    </FormControl>
  )
}
