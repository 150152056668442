import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { createHomeNoValidationRules } from '~utils/fieldValidation'
import PhoneNumberInput from '~components/PhoneNumberInput'
import landlineExamples from '~components/PhoneNumberInput/resources/examples.landline.json'

import { PrefillMap } from '~features/public-form/components/FormFields/FormFields'

import { BaseFieldProps, FieldContainer } from '../FieldContainer'
import { HomeNoFieldSchema, SingleAnswerFieldInput } from '../types'

export interface HomeNoFieldProps extends BaseFieldProps {
  schema: HomeNoFieldSchema
  disableRequiredValidation?: boolean
  prefill?: PrefillMap[string]
}

export const HomeNoField = ({
  schema,
  disableRequiredValidation,
  ...fieldContainerProps
}: HomeNoFieldProps): JSX.Element => {
  const prefill = fieldContainerProps?.prefill?.prefillValue

  const isPrefilled = !!prefill

  // Prefill can be locked only if there is a prefill value
  // If prefill is enabled but no prefill value is provided, it is not locked
  const isPrefillLocked =
    isPrefilled && !!fieldContainerProps?.prefill?.lockPrefill

  const validationRules = useMemo(
    () => createHomeNoValidationRules(schema, disableRequiredValidation),
    [schema, disableRequiredValidation],
  )

  const { control } = useFormContext<SingleAnswerFieldInput>()

  return (
    <FieldContainer schema={schema}>
      <Controller
        control={control}
        rules={validationRules}
        name={schema._id}
        render={({ field }) => (
          <PhoneNumberInput
            autoComplete="tel"
            defaultCountry={schema.defaultCountry}
            allowInternational={schema.allowIntlNumbers}
            examples={landlineExamples}
            prefill={
              schema.allowIntlNumbers && schema.allowPrefill
                ? prefill
                  ? prefill.trim().startsWith('+')
                    ? prefill.trim()
                    : `+${prefill.trim()}`
                  : ''
                : undefined
            }
            isPrefilled={
              schema.allowIntlNumbers && schema.allowPrefill
                ? isPrefilled
                : false
            }
            isPrefillLocked={
              schema.allowIntlNumbers && schema.lockPrefill
                ? isPrefillLocked
                : false
            }
            {...field}
          />
        )}
      />
    </FieldContainer>
  )
}
