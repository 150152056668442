import { Box, Divider, Flex, Link, Stack, Text, Wrap } from '@chakra-ui/react'

import { FooterContainerProps, FooterVariantProps } from './common/types'

interface CompactedFooterProps extends FooterVariantProps {
  compactMonochromeLogos?: boolean
}

/** Desktop only compact footer variant */
export const CompactFooter = ({
  footerIconLink,
  textColorScheme,
  footerLinks,
  compactMonochromeLogos,
  appLink,
  containerProps,
}: CompactedFooterProps): JSX.Element => {
  const currentYear = new Date().getFullYear()

  return (
    <CompactFooter.Container {...containerProps}>
      <Wrap
        flex={1}
        shouldWrapChildren
        textStyle="body-2"
        spacing="1.5rem"
        justify="flex-end"
      >
        <Box>
          <Text
            textStyle="caption-1"
            color={`${textColorScheme}.500`}
            mb="0.5rem"
          >
            Built by
          </Text>
          <Link
            isExternal
            title={footerIconLink.label}
            colorScheme={compactMonochromeLogos ? 'white' : textColorScheme}
            href={footerIconLink.href}
          >
            <Text textStyle="legal" ml={2} color={`${textColorScheme}.500`}>
              ©{currentYear} Open Government Products
            </Text>
          </Link>
        </Box>
      </Wrap>
    </CompactFooter.Container>
  )
}

CompactFooter.Container = ({
  children,
  ...props
}: FooterContainerProps): JSX.Element => {
  return (
    <Flex
      align="center"
      width="100%"
      justify="space-between"
      flexDir="row"
      as="footer"
      {...props}
    >
      {children}
    </Flex>
  )
}
