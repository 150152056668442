import { InputProps } from '~components/Input'

import { PrefillMap } from '~features/public-form/components/FormFields/FormFields'

import { BaseFieldProps, FieldContainer } from '../FieldContainer'
import { EmailFieldSchema } from '../types'

import { EmailFieldInput } from './EmailFieldInput'

export interface EmailFieldProps extends BaseFieldProps {
  schema: EmailFieldSchema
  disableRequiredValidation?: boolean
  errorVariant?: 'white'
  inputProps?: Partial<InputProps>
  prefill?: PrefillMap[string]
}

/**
 * @precondition Must have a parent `react-hook-form#FormProvider` component.
 */
export const EmailField = ({
  schema,
  disableRequiredValidation,
  errorVariant,
  inputProps,
  ...fieldContainerProps
}: EmailFieldProps): JSX.Element => {
  const isPrefilled = !!fieldContainerProps?.prefill?.prefillValue

  // Prefill can be locked only if there is a prefill value
  // If prefill is enabled but no prefill value is provided, it is not locked
  const isPrefillLocked =
    isPrefilled && !!fieldContainerProps?.prefill?.lockPrefill

  return (
    <FieldContainer schema={schema} errorVariant={errorVariant}>
      <EmailFieldInput
        schema={schema}
        disableRequiredValidation={disableRequiredValidation}
        inputProps={inputProps}
        prefill={
          schema.allowPrefill && !schema.hasAllowedEmailDomains
            ? fieldContainerProps?.prefill?.prefillValue
            : undefined
        }
        isPrefilled={
          schema.allowPrefill && !schema.hasAllowedEmailDomains
            ? isPrefilled
            : false
        }
        isPrefillLocked={
          schema.lockPrefill && !schema.hasAllowedEmailDomains
            ? isPrefillLocked
            : false
        }
      />
    </FieldContainer>
  )
}
