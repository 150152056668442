import { Dispatch, SetStateAction } from 'react'
import { Box, Button, forwardRef, Stack, Text } from '@chakra-ui/react'
import { range, takeRight } from 'lodash'

import { ReminderFrequency, ReminderMode } from '~shared/types'

import { FieldColorScheme } from '~theme/foundations/colours'
import { MultiSelect } from '~components/Dropdown'

interface RecurringReminderProps {
  /**
   * Color scheme of the component to render. Defaults to `primary`.
   */
  colorScheme?: FieldColorScheme

  /**
   * Mode of reminder.
   */
  mode: ReminderMode

  /**
   * Frequency for recurring reminder.
   */
  frequency?: ReminderFrequency

  /**
   * Maximum number of day for recurring reminder.
   */
  maxDaySelection?: number

  /**
   * Selected day for recurring reminder.
   */
  selectedDay: string[]

  /**
   * Function to update selectedDay states
   */
  setSelectedDay: Dispatch<SetStateAction<string[]>>
}

export enum DaysInWeek {
  Sunday = 'Sun',
  Monday = 'Mon',
  Tuesday = 'Tue',
  Wednesday = 'Wed',
  Thursday = 'Thu',
  Friday = 'Fri',
  Saturday = 'Sat',
}

/**
 * Recurring reminder component.
 */
export const RecurringReminder = forwardRef<RecurringReminderProps, 'input'>(
  (
    {
      mode,
      frequency,
      colorScheme,
      maxDaySelection,
      selectedDay,
      setSelectedDay,
      ...props
    },
    ref,
  ) => {
    const handleOnClick = (day: string) => {
      if (selectedDay.includes(day)) {
        setSelectedDay(selectedDay.filter((x) => x !== day))
      } else {
        setSelectedDay([
          ...takeRight(
            selectedDay,
            (maxDaySelection ? maxDaySelection : 1) - 1,
          ),
          day,
        ])
      }
    }

    if (mode === ReminderMode.Recurring) {
      if (frequency === ReminderFrequency.Weekly) {
        return (
          <>
            <Text fontSize="medium" pb={1}>
              Select day in week
            </Text>
            <Stack
              direction="row"
              spacing={3}
              align="center"
              pb={4}
              overflow="auto"
            >
              {Object.values(DaysInWeek).map((day) => (
                <Button
                  key={day}
                  colorScheme={colorScheme}
                  variant={selectedDay.includes(day) ? 'solid' : 'outline'}
                  width={18}
                  borderRadius={'1.5rem'}
                  onClick={() => handleOnClick(day)}
                >
                  {day}
                </Button>
              ))}
            </Stack>
          </>
        )
      }
      if (frequency === ReminderFrequency.Monthly) {
        return (
          <Box pb={4}>
            <Text fontSize="medium" pb={1}>
              Select day in month
            </Text>
            <MultiSelect
              name="recurringDate"
              items={range(1, 32).map((m) => String(m))}
              values={selectedDay}
              maxItems={maxDaySelection}
              onChange={(v) => setSelectedDay(v)}
            />
          </Box>
        )
      }
    }
    return null
  },
)
