import { KeyboardEventHandler, useCallback, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Spinner,
  useMergeRefs,
} from '@chakra-ui/react'
import validator from 'validator'

import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'

import { useMutateFormSettings } from '../../mutations'
import { useAdminFormSettings } from '../../queries'

export const BotMDGQLUrlInput = (): JSX.Element => {
  const { data: settings, isLoading } = useAdminFormSettings()
  const { mutateFormBotMDGQLUrl } = useMutateFormSettings()
  const {
    register,
    formState: { errors, isValid },
    resetField,
    getValues,
  } = useForm<{ gqlUrl: string }>({
    mode: 'onChange',
  })

  const handleUpdateGQLUrl = useCallback(() => {
    if (isLoading) return
    const nextUrl = getValues('gqlUrl')
    if (settings?.botmd.gqlUrl === nextUrl) return
    return mutateFormBotMDGQLUrl.mutate(nextUrl, {
      onError: () => resetField('gqlUrl'),
    })
  }, [
    getValues,
    isLoading,
    mutateFormBotMDGQLUrl,
    resetField,
    settings?.botmd.gqlUrl,
  ])

  const handlegqlUrlInputBlur = useCallback(() => {
    if (!isValid) {
      return resetField('gqlUrl')
    }
    return handleUpdateGQLUrl()
  }, [handleUpdateGQLUrl, isValid, resetField])

  const gqlUrlRegister = register('gqlUrl', {
    onBlur: handlegqlUrlInputBlur,
    validate: (url) => {
      return (
        !url ||
        validator.isURL(url, {
          protocols: ['https'],
          require_protocol: true,
        }) ||
        'Please enter a valid URL (starting with https://)'
      )
    },
  })

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (isLoading || !settings) return
    resetField('gqlUrl', { defaultValue: settings.botmd.gqlUrl })
  }, [isLoading, resetField, settings])

  const mergedRefs = useMergeRefs(gqlUrlRegister.ref, inputRef)

  const handleSessionEnterKeyDown: KeyboardEventHandler = useCallback(
    (e) => {
      if (!isValid || e.key !== 'Enter') return
      return inputRef.current?.blur()
    },
    [isValid],
  )

  return (
    <FormControl
      mb="2.5rem"
      isReadOnly={mutateFormBotMDGQLUrl.isLoading}
      isInvalid={!!errors.gqlUrl}
    >
      <FormLabel description="URL for BotMD GQL server.">
        BotMD GQL URL
      </FormLabel>
      <Skeleton isLoaded={!isLoading}>
        <InputGroup>
          {mutateFormBotMDGQLUrl.isLoading ? (
            <InputRightElement pointerEvents="none">
              <Spinner />
            </InputRightElement>
          ) : null}
          <Input
            placeholder="https://botmd-server.io/gql"
            onKeyDown={handleSessionEnterKeyDown}
            {...gqlUrlRegister}
            ref={mergedRefs}
          />
        </InputGroup>
      </Skeleton>
      <FormErrorMessage>{errors.gqlUrl?.message}</FormErrorMessage>
    </FormControl>
  )
}
