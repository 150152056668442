import {
  AddVariableLogic,
  FormLogic,
  PreventSubmitLogic,
  RedirectUrlLogic,
  ReplaceValueLogic,
  ShowFieldLogic,
} from '~shared/types/form'

export enum AdminEditLogicState {
  CreatingLogic,
  EditingLogic,
}

export type EditLogicInputs = FormLogic & {
  addVariableKey?: AddVariableLogic['addVariableKey']
  addVariableValue?: AddVariableLogic['addVariableValue']
  externalLink?: RedirectUrlLogic['externalLink']
  preventSubmitMessage?: PreventSubmitLogic['preventSubmitMessage']
  replaceValueValue?: ReplaceValueLogic['replaceValueQuestion']
  show?: ShowFieldLogic['show']
}
