import { useEffect, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import { FormControl, Stack } from '@chakra-ui/react'
import axios from 'axios'
import { extend, isBoolean, pick, range, values } from 'lodash'

import {
  MessageTemplatePurpose,
  ReminderAction,
  ReminderFieldBase,
  ReminderFrequency,
  ReminderMode,
  ReminderTrigger,
  ReminderTriggerUnit,
} from '~shared/types/field'

import { createBaseValidationRules } from '~utils/fieldValidation'
import { SingleSelect } from '~components/Dropdown'
import { ComboboxItem } from '~components/Dropdown/types'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Input from '~components/Input'
import NumberInput from '~components/NumberInput'
import Textarea from '~components/Textarea'
import Toggle from '~components/Toggle'

import { useAdminFormSettings } from '~features/admin-form/settings/queries'

import { CreatePageDrawerContentContainer } from '../../../../common'

import { FormFieldDrawerActions } from './common/FormFieldDrawerActions'
import { EditFieldProps } from './common/types'
import { useEditFieldForm } from './common/useEditFieldForm'

type EditReminderProps = EditFieldProps<ReminderFieldBase>

const EDIT_REMINDER_FIELD_KEYS = [
  'title',
  'description',
  'required',
  'reminderOptions',
] as const

type EditReminderInputs = Pick<
  ReminderFieldBase,
  typeof EDIT_REMINDER_FIELD_KEYS[number]
>

const EDIT_REMINDER_OPTIONS = {
  mode: Object.keys(ReminderMode),
  actions: Object.keys(ReminderAction),
  startTriggers: Object.keys(ReminderTrigger),
  endTriggers: Object.keys(ReminderTrigger),
  frequencies: Object.keys(ReminderFrequency),
  frequencyDuration: range(1, 11).map((x) => String(x)),
  startTriggerDuration: range(1, 11).map((x) => String(x)),
  endTriggerDuration: range(1, 11).map((x) => String(x)),
  startTriggerUnit: Object.keys(ReminderTriggerUnit),
  endTriggerUnit: Object.keys(ReminderTriggerUnit),
  messageTemplatePurpose: Object.keys(MessageTemplatePurpose),
}

export const EditReminder = ({ field }: EditReminderProps): JSX.Element => {
  const { data: settings } = useAdminFormSettings()
  const [messageTemplates, setMessageTemplates] = useState<ComboboxItem[]>([])
  const [isLoadingGQL, setIsLoadingGQL] = useState(false)

  const {
    register,
    formState: { errors },
    control,
    buttonText,
    handleUpdateField,
    isLoading,
    handleCancel,
    getValues,
  } = useEditFieldForm<EditReminderInputs, ReminderFieldBase>({
    field,
    transform: {
      input: (inputField) => pick(inputField, EDIT_REMINDER_FIELD_KEYS),
      output: (formOutput, originalField) =>
        extend({}, originalField, formOutput),
    },
  })

  const requiredValidationRule = useMemo(
    () => createBaseValidationRules({ required: true }),
    [],
  )

  useEffect(() => {
    if (
      messageTemplates.length === 0 &&
      getValues('reminderOptions.action') === ReminderAction.SendTemplate
    ) {
      setIsLoadingGQL(true)
      const options = {
        method: 'POST',
        url: settings?.botmd.gqlUrl,
        headers: {
          'Content-Type': 'application/json',
          'X-Session': settings?.botmd.cleoSession,
        },
        data: '{"query":"query cleoExternalAPI {cleoExternalApi{messageTemplateSets {uid name}}}"}',
      }

      axios
        .request(options)
        .then(
          ({
            data: {
              data: {
                cleoExternalApi: { messageTemplateSets },
              },
            },
          }) => {
            setMessageTemplates(
              messageTemplateSets.map(
                ({ name, uid }: { name: string; uid: string }) => ({
                  label: name,
                  value: uid,
                }),
              ),
            )
            setIsLoadingGQL(false)
          },
        )
        .catch((error) => {
          console.error(error)
        })
    }
  }, [getValues, settings, messageTemplates])

  return (
    <CreatePageDrawerContentContainer>
      <FormControl isRequired isReadOnly={isLoading} isInvalid={!!errors.title}>
        <FormLabel>Question</FormLabel>
        <Input autoFocus {...register('title', requiredValidationRule)} />
        <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isReadOnly={isLoading} isInvalid={!!errors.description}>
        <FormLabel>Description</FormLabel>
        <Textarea {...register('description')} />
        <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isReadOnly={isLoading}>
        <Toggle {...register('required')} label="Required" />
      </FormControl>
      <FormControl id="reminderOptions.mode" isReadOnly={isLoading}>
        <FormLabel isRequired>Mode</FormLabel>
        <Controller
          control={control}
          name="reminderOptions.mode"
          render={({ field }) => (
            <SingleSelect
              isClearable={false}
              items={EDIT_REMINDER_OPTIONS.mode}
              {...field}
            />
          )}
        />
      </FormControl>
      {getValues('reminderOptions.mode') === ReminderMode.Recurring && (
        <>
          <FormControl
            id="reminderOptions.frequency"
            isReadOnly={isLoading || isLoadingGQL}
          >
            <FormLabel isRequired>Recurring Frequency</FormLabel>
            <Controller
              control={control}
              name="reminderOptions.frequency"
              render={({ field: { value, ...field } }) => (
                <SingleSelect
                  isClearable={false}
                  items={EDIT_REMINDER_OPTIONS.frequencies}
                  value={value ? String(value) : ''}
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl
            id="reminderOptions.frequencyDuration"
            isReadOnly={isLoading || isLoadingGQL}
          >
            <FormLabel isRequired pt={4}>
              Duration
            </FormLabel>
            <Controller
              control={control}
              name="reminderOptions.frequencyDuration"
              render={({ field: { value, ...field } }) => (
                <SingleSelect
                  isClearable={false}
                  items={EDIT_REMINDER_OPTIONS.frequencyDuration}
                  value={value ? String(value) : ''}
                  {...field}
                />
              )}
            />
          </FormControl>
          {getValues('reminderOptions.frequency') ===
            ReminderFrequency.Weekly && (
            <FormControl
              id="reminderOptions.maxDaySelection"
              isReadOnly={isLoading || isLoadingGQL}
            >
              <FormLabel isRequired pt={4}>
                Maximum number of day selection
              </FormLabel>
              <Controller
                control={control}
                name="reminderOptions.maxDaySelection"
                render={({ field: { value, ...field } }) => (
                  <NumberInput
                    flex={1}
                    inputMode="numeric"
                    value={value ? value : 1}
                    {...field}
                  />
                )}
              />
            </FormControl>
          )}
        </>
      )}
      <>
        <FormControl id="reminderOptions.startTrigger" isReadOnly={isLoading}>
          <FormLabel isRequired>Start Trigger</FormLabel>
          <Controller
            control={control}
            name="reminderOptions.startTrigger"
            render={({ field: { value, ...field } }) => (
              <SingleSelect
                isClearable={true}
                items={EDIT_REMINDER_OPTIONS.startTriggers}
                value={value ? String(value) : ''}
                {...field}
              />
            )}
          />
        </FormControl>
        <FormControl
          id="reminderOptions.startTriggerTime"
          isReadOnly={isLoading}
        >
          <FormLabel pt={4} isRequired>
            Start Trigger Time
          </FormLabel>
          <Controller
            control={control}
            name="reminderOptions.startTriggerTime"
            render={({ field: { value, onChange, ...field } }) => (
              <Stack direction="row" spacing={4} align="center" pb={4}>
                <SingleSelect
                  name="hour"
                  value={value ? String(value.substring(0, 2)) : ''}
                  isClearable={true}
                  placeholder="Select Hour"
                  items={range(24).map((h) => String(h).padStart(2, '0'))}
                  onChange={(data) => {
                    if (data) {
                      onChange(`${data}:${value ? value.slice(-2) : '00'}`)
                    } else {
                      onChange(null)
                    }
                  }}
                />
                <SingleSelect
                  name="minute"
                  value={value ? value.slice(-2) : ''}
                  isClearable={true}
                  placeholder="Select Minute"
                  items={range(60).map((m) => String(m).padStart(2, '0'))}
                  onChange={(data) => {
                    if (data) {
                      onChange(
                        `${value ? value.substring(0, 2) : '00'}:${data}`,
                      )
                    } else {
                      onChange(null)
                    }
                  }}
                />
              </Stack>
            )}
          />
        </FormControl>
        <FormControl
          id="reminderOptions.startTriggerUnit"
          isReadOnly={isLoading}
        >
          <FormLabel isRequired>Start Trigger Unit</FormLabel>
          <Controller
            control={control}
            name="reminderOptions.startTriggerUnit"
            render={({ field: { value, ...field } }) => (
              <SingleSelect
                isClearable={true}
                items={EDIT_REMINDER_OPTIONS.startTriggerUnit}
                value={value ? String(value) : ''}
                {...field}
              />
            )}
          />
        </FormControl>
        <FormControl
          id="reminderOptions.startTriggerIsAfter"
          isReadOnly={isLoading}
        >
          <FormLabel pt={4} isRequired>
            Start Trigger
          </FormLabel>
          <Controller
            control={control}
            name="reminderOptions.startTriggerIsAfter"
            render={({ field: { value, onChange, ...field } }) => (
              <SingleSelect
                isClearable={true}
                items={['After Event', 'Before Event']}
                value={
                  isBoolean(value)
                    ? value
                      ? 'After Event'
                      : 'Before Event'
                    : ''
                }
                onChange={(x) => {
                  if (x) {
                    onChange(x === 'After Event')
                  } else {
                    onChange(null)
                  }
                }}
                {...field}
              />
            )}
          />
        </FormControl>
      </>
      <>
        <FormControl id="reminderOptions.endTrigger" isReadOnly={isLoading}>
          <FormLabel isRequired>End Trigger</FormLabel>
          <Controller
            control={control}
            name="reminderOptions.endTrigger"
            render={({ field: { value, ...field } }) => (
              <SingleSelect
                isClearable={true}
                items={EDIT_REMINDER_OPTIONS.endTriggers}
                value={value ? String(value) : ''}
                {...field}
              />
            )}
          />
        </FormControl>
        <FormControl id="reminderOptions.endTriggerTime" isReadOnly={isLoading}>
          <FormLabel pt={4} isRequired>
            End Trigger Time
          </FormLabel>
          <Controller
            control={control}
            name="reminderOptions.endTriggerTime"
            render={({ field: { value, onChange, ...field } }) => (
              <Stack direction="row" spacing={4} align="center" pb={4}>
                <SingleSelect
                  name="hour"
                  value={value ? String(value.substring(0, 2)) : ''}
                  isClearable={true}
                  placeholder="Select Hour"
                  items={range(24).map((h) => String(h).padStart(2, '0'))}
                  onChange={(data) => {
                    if (data) {
                      onChange(`${data}:${value ? value.slice(-2) : '00'}`)
                    } else {
                      onChange(null)
                    }
                  }}
                />
                <SingleSelect
                  name="minute"
                  value={value ? value.slice(-2) : ''}
                  isClearable={true}
                  placeholder="Select Minute"
                  items={range(60).map((m) => String(m).padStart(2, '0'))}
                  onChange={(data) => {
                    if (data) {
                      onChange(
                        `${value ? value.substring(0, 2) : '00'}:${data}`,
                      )
                    } else {
                      onChange(null)
                    }
                  }}
                />
              </Stack>
            )}
          />
        </FormControl>
        <FormControl id="reminderOptions.endTriggerUnit" isReadOnly={isLoading}>
          <FormLabel isRequired>End Trigger Unit</FormLabel>
          <Controller
            control={control}
            name="reminderOptions.endTriggerUnit"
            render={({ field: { value, ...field } }) => (
              <SingleSelect
                isClearable={true}
                items={EDIT_REMINDER_OPTIONS.endTriggerUnit}
                value={value ? String(value) : ''}
                {...field}
              />
            )}
          />
        </FormControl>
        <FormControl
          id="reminderOptions.endTriggerIsAfter"
          isReadOnly={isLoading}
        >
          <FormLabel pt={4} isRequired>
            End Trigger
          </FormLabel>
          <Controller
            control={control}
            name="reminderOptions.endTriggerIsAfter"
            render={({ field: { value, onChange, ...field } }) => (
              <SingleSelect
                isClearable={true}
                items={['After Event', 'Before Event']}
                value={
                  isBoolean(value)
                    ? value
                      ? 'After Event'
                      : 'Before Event'
                    : ''
                }
                onChange={(x) => {
                  if (x) {
                    onChange(x === 'After Event')
                  } else {
                    onChange(null)
                  }
                }}
                {...field}
              />
            )}
          />
        </FormControl>
      </>
      <FormControl id="reminderOptions.action" isReadOnly={isLoading}>
        <FormLabel isRequired>Action</FormLabel>
        <Controller
          control={control}
          name="reminderOptions.action"
          render={({ field }) => (
            <SingleSelect
              isClearable={false}
              items={EDIT_REMINDER_OPTIONS.actions}
              {...field}
            />
          )}
        />
      </FormControl>
      {getValues('reminderOptions.action') === ReminderAction.SendTemplate && (
        <>
          <FormControl
            id="reminderOptions.messageTemplatePurpose"
            isReadOnly={isLoading}
          >
            <FormLabel isRequired>Purpose</FormLabel>
            <Controller
              control={control}
              name="reminderOptions.messageTemplatePurpose"
              render={({ field: { value, ...field } }) => (
                <SingleSelect
                  isClearable={false}
                  items={EDIT_REMINDER_OPTIONS.messageTemplatePurpose}
                  value={value ? String(value) : ''}
                  {...field}
                />
              )}
            />
          </FormControl>
          <FormControl
            pt={4}
            id="reminderOptions.messageTemplate"
            isReadOnly={isLoading || isLoadingGQL}
          >
            <FormLabel isRequired>Message Template</FormLabel>
            <Controller
              control={control}
              name="reminderOptions.messageTemplate"
              render={({ field: { value, ...field } }) => (
                <SingleSelect
                  isClearable={false}
                  items={messageTemplates}
                  value={value ? String(value) : ''}
                  {...field}
                />
              )}
            />
          </FormControl>
        </>
      )}
      <FormFieldDrawerActions
        isLoading={isLoading}
        buttonText={buttonText}
        handleClick={handleUpdateField}
        handleCancel={handleCancel}
      />
    </CreatePageDrawerContentContainer>
  )
}
