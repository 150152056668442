import { BasicField, MyInfoableFieldBase } from './base'

export enum BotMDDataType {
  Clinic = 'clinic',
  Clinician = 'clinician',
  Reminder = 'reminder',
}

export interface BotMDDropdownFieldBase extends MyInfoableFieldBase {
  fieldType: BasicField.BotMDDropdown
  dataType?: string
}
