export const myInfoRaces = [
  'ACHEHNESE',
  'AFGHAN',
  'AFRICAN',
  'ALBANIAN',
  'AMBONESE',
  'AMERICAN',
  'AMERINDIAN',
  'ANGLO BURMESE',
  'ANGLO CHINESE',
  'ANGLO FILIPINO',
  'ANGLO INDIAN',
  'ANGLO SAXON',
  'ANGLO THAI',
  'ANNAMITE',
  'ARAB',
  'ARMENIAN',
  'ARYAN',
  'ASSAMI',
  'AUSTRALIAN',
  'AUSTRIAN',
  'AZERI',
  'BAJAU',
  'BALINESE',
  'BAMAR',
  'BANGALA',
  'BANGLADESHI',
  'BANJARESE',
  'BANTEN',
  'BASQUE',
  'BATAK',
  'BELGIAN',
  'BENGALI',
  'BETAWI',
  'BHUTANESE',
  'BIDAYUH',
  'BISAYA',
  'BOSNIAK',
  'BOYANESE',
  'BRAHMIN',
  'BRAZILIAN',
  'BRITISH',
  'BUGIS',
  'BULGARIAN',
  'BURGHER',
  'BURMESE',
  'BUTONESE',
  'CAMBODIAN',
  'CANADIAN',
  'CAPE COLOURED',
  'CARIBBEAN',
  'CAUCASIAN',
  'CEYLON MOOR',
  'CEYLONESE',
  'CHAMORRO',
  'CHINESE',
  'COCOS',
  'CORNISH',
  'CREOLE',
  'CROAT',
  'CZECH',
  'CZECHOSLOVAK',
  'DANE',
  'DAYAK',
  'DUSUN',
  'DUTCH',
  'DUTCH BURGHER',
  'EGYPTIAN',
  'ENGLISH',
  'ETHIOPIAN',
  'EURASIAN',
  'EUROPEAN',
  'FIJIAN',
  'FILIPINO',
  'FINN',
  'FLEMISH',
  'FRENCH',
  'GERMAN',
  'GHANAIAN',
  'GOAN',
  'GOANESE',
  'GREEK',
  'GUJARATI',
  'GURKHA',
  'HAITIAN',
  'HAWAIIAN',
  'HINDUSTANI',
  'HISPANIC',
  'HOLLANDER',
  'HUNGARIAN',
  'IBAN',
  'ICELANDER',
  'INDIAN',
  'INDONESIAN',
  'INUIT',
  'IRANIAN',
  'IRAQI',
  'IRISH',
  'ISOKO',
  'ISRAELI',
  'ITALIAN',
  'JAKUN',
  'JAMAICAN',
  'JAPANESE',
  'JAVANESE',
  'JEW',
  'JORDANIAN',
  'KACHIN',
  'KADAZAN',
  'KAMPUCHEAN',
  'KAREN',
  'KAYAH',
  'KAYAN',
  'KAYIN',
  'KAZAKH',
  'KELABIT',
  'KENYAH',
  'KENYAN',
  'KHASI',
  'KHMER',
  'KINH',
  'KOREAN',
  'KYRGYZ',
  'LAO',
  'LATIN',
  'LATVIAN',
  'LEBANESE',
  'LIBYAN',
  'LITHUANIAN',
  'LUXEMBOURGER',
  'MADURESE',
  'MAGYARS',
  'MAHRATTA',
  'MAKASARESE',
  'MALABARI',
  'MALAGASY',
  'MALAY',
  'MALAYALEE',
  'MALDIVIAN',
  'MALTESE',
  'MANIPURI',
  'MANX',
  'MAORI',
  'MARATHI',
  'MAURITIAN',
  'MELANAU',
  'MELANESIAN',
  'MESTIZO',
  'METIS',
  'MEXICAN',
  'MINANGKABAU',
  'MOLDAVIAN',
  'MON',
  'MONGOLIAN',
  'MOROCCAN',
  'MURUT',
  'NAGA',
  'NAURUAN',
  'NEGRO',
  'NEPALESE',
  'NETHERLANDER',
  'NEW ZEALANDER',
  'NEWAR',
  'NIGERIAN',
  'NORWEGIAN',
  'OTHER EURASIAN',
  'OTHER INDIAN',
  'OTHER INDONESIAN',
  'OTHERS',
  'PAKISTANI',
  'PALESTINE',
  'PARSEE',
  'PATHAN',
  'PENAN',
  'PERSIAN',
  'PERUVIAN',
  'POLE',
  'POLISH',
  'POLYNESIAN',
  'PORTUGUESE',
  'PUNJABI',
  'RAJPUT',
  'RAKHINE',
  'ROMANIAN',
  'RUSSIAN',
  'SAMMARINESE',
  'SAMOAN',
  'SCOT',
  'SERANI',
  'SERBIA/MONTENGERIN',
  'SEYCHELLOIS',
  'SHAN',
  'SIKH',
  'SINDHI',
  'SINHALESE',
  'SINO INDIAN',
  'SINO JAPANESE',
  'SINO KADAZAN',
  'SLAVIC',
  'SLOVAK',
  'SOMALI',
  'SPANISH',
  'SRI LANKAN',
  'SUDANESE',
  'SUMATRAN',
  'SUNDANESE',
  'SWEDE',
  'SWEDISH',
  'SWISS',
  'TAJIK',
  'TAMIL',
  'TELUGU',
  'THAI',
  'TIBETAN',
  'TIMOR',
  'TONGAN',
  'TURK',
  'TURKMEN',
  'UKRAINIAN',
  'UNKNOWN',
  'UYGHUR',
  'UZBEK',
  'VENEZUELAN',
  'VIETNAMESE',
  'WELSH',
  'YUGOSLAV',
  'ZIMBABWEAN',
]
