// hands-clapping icon taken from  https://phosphoricons.com/.

export const PhHandsClapping = (
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element => {
  return (
    <svg viewBox="0 0 22 24" fill="none" height="1em" width="1em" {...props}>
      <path
        stroke="currentColor"
        strokeWidth={1.75}
        d="M4.165 11.667a1.724 1.724 0 0 0-.436-.51 1.679 1.679 0 0 0-1.254-.344c-.22.03-.434.104-.627.218a1.71 1.71 0 0 0-.497.446 1.755 1.755 0 0 0-.337 1.284c.03.227.102.445.213.642l3.484 6.183a6.8 6.8 0 0 0 4.115 3.186 6.639 6.639 0 0 0 5.113-.702 6.923 6.923 0 0 0 3.15-4.185 7.093 7.093 0 0 0-.637-5.244l-1.6-2.845a1.696 1.696 0 0 0-1.026-.73 1.66 1.66 0 0 0-1.236.187 1.73 1.73 0 0 0-.774 1.004c-.124.424-.086.88.107 1.275m-2.56 2.411L5.783 7.597a1.725 1.725 0 0 0-.436-.51 1.68 1.68 0 0 0-1.254-.344c-.22.03-.433.104-.626.217a1.709 1.709 0 0 0-.498.447 1.754 1.754 0 0 0-.336 1.284c.029.226.101.445.212.642l3.578 6.346m7.006-1.466L9.475 7.192a1.702 1.702 0 0 0-1.03-.81 1.66 1.66 0 0 0-1.286.173c-.39.23-.674.61-.79 1.055-.117.445-.056.919.168 1.318M8.61 6.44l-.97-1.727a1.702 1.702 0 0 0-1.03-.81 1.66 1.66 0 0 0-1.286.174c-.39.23-.674.61-.79 1.054-.117.445-.056.92.168 1.318l.226.396M16.99 18.38a7.005 7.005 0 0 0 2.149-4.071 7.098 7.098 0 0 0-.822-4.552l-1.6-2.835a1.724 1.724 0 0 0-.436-.51 1.68 1.68 0 0 0-1.254-.344c-.22.03-.434.103-.626.217a1.709 1.709 0 0 0-.498.447 1.753 1.753 0 0 0-.336 1.284c.029.226.1.444.212.642m.217.386L11.33 4.318a1.702 1.702 0 0 0-1.03-.81 1.66 1.66 0 0 0-1.286.173c-.39.23-.674.61-.79 1.055-.117.445-.056.919.168 1.318M15.934 1l-.386 1.485m3.24-.125-.867 1.263M21 4.626l-1.233.888"
      />
    </svg>
  )
}
