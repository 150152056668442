import { BotMDSettingsSection } from './components/BotMDSettingsSection'
import { CategoryHeader } from './components/CategoryHeader'

export const SettingsBotMDPage = (): JSX.Element => {
  return (
    <>
      <CategoryHeader>BotMD Settings</CategoryHeader>
      <BotMDSettingsSection />
    </>
  )
}
