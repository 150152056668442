export enum CountryRegion {
  Afghanistan = 'Afghanistan',
  Albania = 'Albania',
  Algeria = 'Algeria',
  American_Samoa = 'American Samoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  Antigua = 'Antigua',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bhutan = 'Bhutan',
  Bolivia = 'Bolivia',
  Bosnia_Herzegovina = 'Bosnia-Herzegovina',
  Botswana = 'Botswana',
  Brazil = 'Brazil',
  British_Antarctic_Territory = 'British Antarctic Territory',
  British_Indian_Ocean_Territory = 'British Indian Ocean Territory',
  British_Virgin_Islands = 'British Virgin Islands',
  Brunei = 'Brunei',
  Bulgaria = 'Bulgaria',
  Burkina_Faso = 'Burkina Faso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  Canton_and_Enderbury_Islands = 'Canton & Enderbury Islands',
  Cape_Verde = 'Cape Verde',
  Cayman_Islands = 'Cayman Islands',
  Central_African_Republic = 'Central African Republic',
  Chad = 'Chad',
  Channel_Islands = 'Channel Islands',
  Chile = 'Chile',
  China = 'China',
  Christmas_Island = 'Christmas Island',
  Cocos_Keeling_Island = 'Cocos Keeling Island',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  Cook_Islands = 'Cook Islands',
  Costa_Rica = 'Costa Rica',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Cyprus = 'Cyprus',
  Czech_Republic = 'Czech Republic',
  Denmark = 'Denmark',
  Djibouti = 'Djibouti',
  Dominica = 'Dominica',
  Dominican_Republic = 'Dominican Republic',
  East_Timor = 'East Timor',
  Ecuador = 'Ecuador',
  Egypt = 'Egypt',
  El_Salvador = 'El Salvador',
  Equatorial_Guinea = 'Equatorial Guinea',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  Faeroe_Islands = 'Faeroe Islands',
  Falkland_Islands = 'Falkland Islands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  French_Guiana = 'French Guiana',
  French_Polynesia = 'French Polynesia',
  French_Southern_and_Antarctic_Territories = 'French Southern & Antarctic Territories',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Gaza = 'Gaza',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadeloupe = 'Guadeloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guernsey = 'Guernsey',
  Guinea = 'Guinea',
  Guinea_Bissau = 'Guinea-Bissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  Heard_and_McDonald_Island = 'Heard & McDonald Island',
  Honduras = 'Honduras',
  Hong_Kong = 'Hong Kong',
  Hong_Kong_SAR = 'Hong Kong SAR',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  Isle_Of_Man = 'Isle Of Man',
  Israel = 'Israel',
  Italy = 'Italy',
  Ivory_Coast = 'Ivory Coast',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Johnston_Island = 'Johnston Island',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kirghizia = 'Kirghizia',
  Kiribati = 'Kiribati',
  Korea_North = 'Korea, North',
  Korea_South = 'Korea, South',
  Kosovo = 'Kosovo',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  Laos = 'Laos',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  Libya = 'Libya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macao = 'Macao',
  Macau_SAR = 'Macau SAR',
  Macedonia = 'Macedonia',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  Marshall_Islands = 'Marshall Islands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  Midway_Islands = 'Midway Islands',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montenegro = 'Montenegro',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  Netherlands_Antililles = 'Netherlands Antililles',
  New_Caledonia = 'New Caledonia',
  New_Zealand = 'New Zealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  Niue_Island = 'Niue Island',
  Norfolk_Island = 'Norfolk Island',
  Norway = 'Norway',
  Oman = 'Oman',
  Others = 'Others',
  Pacific_Island_Trust_Territory = 'Pacific Island Trust Territory',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  Palestine = 'Palestine',
  Panama = 'Panama',
  Panama_Canal_Zone = 'Panama Canal Zone',
  Papua_New_Guinea = 'Papua New Guinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Pitcairn_Island = 'Pitcairn Island',
  Poland = 'Poland',
  Portugal = 'Portugal',
  Puerto_Rico = 'Puerto Rico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  Romania = 'Romania',
  Russia = 'Russia',
  Rwanda = 'Rwanda',
  Samoa = 'Samoa',
  San_Marino = 'San Marino',
  Sao_Tome_and_Principe = 'Sao Tome & Principe',
  Saudi_Arabia = 'Saudi Arabia',
  Senegal = 'Senegal',
  Serbia = 'Serbia',
  Serbia_Montenegro = 'Serbia/Montenegro',
  Seychelles = 'Seychelles',
  Sierra_Leone = 'Sierra Leone',
  Singapore = 'Singapore',
  Slovak_Republic = 'Slovak Republic',
  Slovenia = 'Slovenia',
  Solomon_Islands = 'Solomon Islands',
  Somalia = 'Somalia',
  South_Africa = 'South Africa',
  Spain = 'Spain',
  Sri_Lanka = 'Sri Lanka',
  St_Helena = 'St. Helena',
  St_Kitts_Nevis = 'St. Kitts-Nevis',
  St_Lucia = 'St. Lucia',
  St_Pierre_and_Miquelon = 'St. Pierre & Miquelon',
  St_Vincent = 'St. Vincent',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Syria = 'Syria',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  Timor = 'Timor',
  Togo = 'Togo',
  Tokelau_Islands = 'Tokelau Islands',
  Tonga = 'Tonga',
  Trinidad_and_Tobago = 'Trinidad & Tobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  Turks_and_Caicos_Islands = 'Turks & Caicos Islands',
  Tuvalu = 'Tuvalu',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  United_Arab_Emirates = 'United Arab Emirates',
  United_Kingdom = 'United Kingdom',
  United_States = 'United States',
  Unknown = 'Unknown',
  Upper_Volta = 'Upper Volta',
  Uruguay = 'Uruguay',
  US_Virgin_Islands = 'US Virgin Islands',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Vatican_City_State = 'Vatican City State',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  Wake_Island = 'Wake Island',
  Wallis_and_Futuna = 'Wallis and Futuna',
  Western_Sahara = 'Western Sahara',
  Yemen = 'Yemen',
  Yugoslavia = 'Yugoslavia',
  Zaire = 'Zaire',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe',
}
