import { useEffect, useMemo, useRef } from 'react'
import {
  Box,
  FormControl,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from '@chakra-ui/react'
import { extend, pick } from 'lodash'

import { MobileFieldBase } from '~shared/types/field'

import { GUIDE_PREFILL } from '~constants/links'
import { createBaseValidationRules } from '~utils/fieldValidation'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Input from '~components/Input'
import Textarea from '~components/Textarea'
import Toggle from '~components/Toggle'
import { CopyButton } from '~templates/CopyButton'

import { useFreeSmsQuota } from '~features/admin-form/common/queries'

import { CreatePageDrawerContentContainer } from '../../../../../common'
import { useCreateTabForm } from '../../../../useCreateTabForm'
import { FormFieldDrawerActions } from '../common/FormFieldDrawerActions'
import { EditFieldProps } from '../common/types'
import { useEditFieldForm } from '../common/useEditFieldForm'

import { SmsCountMessage } from './SmsCountMessage'
import { SmsCountsModal } from './SmsCountsModal'
import { TwilioCredentialsMessage } from './TwilioCredentialsMessage'

const EDIT_MOBILE_KEYS = [
  'title',
  'description',
  'required',
  'isVerifiable',
  'allowIntlNumbers',
  'defaultCountry',
  'allowPrefill',
  'lockPrefill',
] as const

type EditMobileProps = EditFieldProps<MobileFieldBase>

type EditMobileInputs = Pick<MobileFieldBase, typeof EDIT_MOBILE_KEYS[number]>

export const EditMobile = ({ field }: EditMobileProps): JSX.Element => {
  const {
    watch,
    register,
    formState: { errors },
    buttonText,
    handleUpdateField,
    isLoading,
    handleCancel,
  } = useEditFieldForm<EditMobileInputs, MobileFieldBase>({
    field,
    transform: {
      input: (inputField) => pick(inputField, EDIT_MOBILE_KEYS),
      output: (formOutput, originalField) =>
        extend({}, originalField, formOutput),
    },
  })

  const requiredValidationRule = useMemo(
    () => createBaseValidationRules({ required: true }),
    [],
  )

  const { data: form } = useCreateTabForm()
  const hasTwilioCredentials = useMemo(() => !!form?.msgSrvcName, [form])

  const { data: freeSmsCount } = useFreeSmsQuota()
  const isToggleVfnDisabled = useMemo(() => {
    if (!freeSmsCount) return true
    return (
      !field.isVerifiable &&
      !hasTwilioCredentials &&
      freeSmsCount.freeSmsCounts >= freeSmsCount.quota
    )
  }, [field.isVerifiable, freeSmsCount, hasTwilioCredentials])

  const smsCountsDisclosure = useDisclosure()

  const hasLockPrefillRef = useRef<HTMLInputElement>(null)
  const hasInternationalRef = useRef<HTMLInputElement>(null)

  const allowPrefillRegister = useMemo(
    () => register('allowPrefill'),
    [register],
  )
  const lockPrefillRegister = useMemo(() => register('lockPrefill'), [register])

  const mergedAllowPrefillRef = useMergeRefs(
    hasInternationalRef,
    allowPrefillRegister.ref,
  )

  const mergedLockPrefillRef = useMergeRefs(
    hasLockPrefillRef,
    lockPrefillRegister.ref,
  )

  const watchAllowPrefill = watch('allowPrefill')
  const watchLockPrefill = watch('lockPrefill')
  const watchLockAllowInternational = watch('allowIntlNumbers')

  useEffect(() => {
    // Prefill must be enabled for lockPrefill
    // We cannot simply use setValue as it does not update
    // the UI
    if (!watchAllowPrefill && watchLockPrefill) {
      hasLockPrefillRef.current?.click()
    }
    if (!watchLockAllowInternational) {
      hasInternationalRef.current?.click()
    }
  }, [watchAllowPrefill, watchLockPrefill, watchLockAllowInternational])

  return (
    <>
      <CreatePageDrawerContentContainer>
        <FormControl
          isRequired
          isReadOnly={isLoading}
          isInvalid={!!errors.title}
        >
          <FormLabel>Question</FormLabel>
          <Input autoFocus {...register('title', requiredValidationRule)} />
          <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isReadOnly={isLoading}
          isInvalid={!!errors.description}
        >
          <FormLabel>Description</FormLabel>
          <Textarea {...register('description')} />
          <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isReadOnly={isLoading}>
          <FormLabel>Default Country (e.g. SG, PH, ID)</FormLabel>
          <Input {...register('defaultCountry')} />
        </FormControl>
        <FormControl isReadOnly={isLoading}>
          <Toggle {...register('required')} label="Required" />
        </FormControl>
        <FormControl isReadOnly={isLoading}>
          <Toggle
            {...register('allowIntlNumbers')}
            label="Allow international numbers"
          />
        </FormControl>
        <Box>
          <FormControl isReadOnly={isLoading} isDisabled={isToggleVfnDisabled}>
            <Toggle
              {...register('isVerifiable', {
                onChange: (e) => {
                  if (e.target.checked && !hasTwilioCredentials) {
                    smsCountsDisclosure.onOpen()
                  }
                },
              })}
              label="OTP verification"
              description="Respondents must verify by entering a code sent to them. If you have added Twilio credentials, please test this OTP verification feature to make sure your credentials are accurate."
            />
          </FormControl>
          <SmsCountMessage
            hasTwilioCredentials={hasTwilioCredentials}
            freeSmsCount={freeSmsCount}
          />
          <TwilioCredentialsMessage
            freeSmsCount={freeSmsCount}
            hasTwilioCredentials={hasTwilioCredentials}
          />
        </Box>
        <FormControl isReadOnly={isLoading}>
          <Toggle
            {...allowPrefillRegister}
            label="Enable pre-fill"
            ref={mergedAllowPrefillRef}
            description={`Use Field ID in the form URL to pre-fill this field for respondents. [Learn how](${GUIDE_PREFILL})`}
            isDisabled={!watchLockAllowInternational}
          />
          {watchAllowPrefill ? (
            <>
              <InputGroup mt="0.5rem">
                <Input
                  isReadOnly
                  isDisabled={!field._id}
                  value={
                    field._id ??
                    'Field ID will be generated after this field is saved'
                  }
                />
                {field._id ? (
                  <InputRightElement>
                    <CopyButton
                      stringToCopy={field._id}
                      aria-label="Copy field ID value"
                    />
                  </InputRightElement>
                ) : null}
              </InputGroup>
            </>
          ) : null}
        </FormControl>
        <FormControl isReadOnly={isLoading}>
          <Toggle
            {...lockPrefillRegister}
            ref={mergedLockPrefillRef}
            label="Prevent pre-fill editing"
            description="This prevents respondents from clicking the field to edit it. However, field content can still be modified via the URL."
            isDisabled={!watchAllowPrefill || !watchLockAllowInternational}
          />
        </FormControl>
        <FormFieldDrawerActions
          isLoading={isLoading}
          buttonText={buttonText}
          handleClick={handleUpdateField}
          handleCancel={handleCancel}
        />
      </CreatePageDrawerContentContainer>
      <SmsCountsModal
        freeSmsCount={freeSmsCount}
        isOpen={smsCountsDisclosure.isOpen}
        onClose={smsCountsDisclosure.onClose}
      />
    </>
  )
}
