/**
 * @precondition Must have a parent `react-hook-form#FormProvider` component.
 */
import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { FormColorTheme } from '~shared/types'

import { createReminderValidationRules } from '~/utils/fieldValidation'

import Reminder from '~components/BotMD/Reminder'

import { BaseFieldProps, FieldContainer } from '../FieldContainer'
import { ReminderFieldInput, ReminderFieldSchema } from '../types'

export interface ReminderFieldProps extends BaseFieldProps {
  schema: ReminderFieldSchema
  disableRequiredValidation?: boolean
}

export const ReminderField = ({
  schema,
  disableRequiredValidation,
  colorTheme = FormColorTheme.Blue,
}: ReminderFieldProps): JSX.Element => {
  const validationRules = useMemo(
    () => createReminderValidationRules(schema, disableRequiredValidation),
    [schema, disableRequiredValidation],
  )

  const { control } = useFormContext<ReminderFieldInput>()

  return (
    <FieldContainer schema={schema}>
      <Controller
        control={control}
        rules={validationRules}
        name={schema._id}
        render={({ field }) => (
          <Reminder
            schema={schema}
            colorScheme={`theme-${colorTheme}`}
            title={`${schema.questionNumber}. ${schema.title}`}
            {...field}
          />
        )}
      />
    </FieldContainer>
  )
}
