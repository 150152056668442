import { useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import axios from 'axios'
import { chain, sortBy } from 'lodash'

import { BotMDDataType, FormColorTheme } from '~shared/types'

import { createBotMDDropdownValidationRules } from '~utils/fieldValidation'
import { SingleSelect } from '~components/Dropdown/SingleSelect'
import { ComboboxItem } from '~components/Dropdown/types'

import { useAdminFormSettings } from '~features/admin-form/settings/queries'

import { BaseFieldProps, FieldContainer } from '../FieldContainer'
import { BotMDDropdownFieldSchema, SingleAnswerFieldInput } from '../types'

export interface BotMDDropdownFieldProps extends BaseFieldProps {
  schema: BotMDDropdownFieldSchema
  disableRequiredValidation?: boolean
}

/**
 * @precondition Must have a parent `react-hook-form#FormProvider` component.
 */
export const BotMDDropdownField = ({
  schema,
  disableRequiredValidation,
  colorTheme = FormColorTheme.Blue,
  ...fieldContainerProps
}: BotMDDropdownFieldProps): JSX.Element => {
  const rules = useMemo(() => {
    return createBotMDDropdownValidationRules(schema, disableRequiredValidation)
  }, [schema, disableRequiredValidation])

  const { control } = useFormContext<SingleAnswerFieldInput>()
  const { data: settings } = useAdminFormSettings()
  const [dropdownOptions, setDropdownOptions] = useState<ComboboxItem[]>([])
  const [isLoadingGQL, setIsLoadingGQL] = useState(null)

  useEffect(() => {
    if (
      dropdownOptions.length === 0 &&
      settings?.botmd &&
      isLoadingGQL === null
    ) {
      setIsLoadingGQL(true)

      const get_gql_query = () => {
        if (schema.dataType === BotMDDataType.Clinician) {
          return '{"query":"query cleoExternalAPI {cleoExternalApi{clinicians {uid name}}}"}'
        }

        if (schema.dataType === BotMDDataType.Reminder) {
          return '{"query":"query cleoExternalAPI {cleoExternalApi{reminders {uid name}}}"}'
        }

        if (schema.dataType === BotMDDataType.Clinic) {
          return '{"query":"query cleoExternalAPI {cleoExternalApi{clinics {domain name}}}"}'
        }
        return null
      }

      const options = {
        method: 'POST',
        url: settings?.botmd.gqlUrl,
        headers: {
          'Content-Type': 'application/json',
          'X-Session': settings?.botmd.cleoSession,
        },
        data: get_gql_query(),
      }

      axios
        .request(options)
        .then(
          ({
            data: {
              data: {
                cleoExternalApi: { clinicians, reminders, clinics },
              },
            },
          }) => {
            if (schema.dataType === BotMDDataType.Clinician) {
              setDropdownOptions(
                chain(clinicians)
                  .sortBy(({ name }: { name: string }) => name.toLowerCase())
                  .map(({ name, uid }: { name: string; uid: string }) => ({
                    label: name,
                    value: uid,
                  }))
                  .value(),
              )
            }
            if (schema.dataType === BotMDDataType.Reminder) {
              setDropdownOptions(
                chain(reminders)
                  .sortBy(({ name }: { name: string }) => name.toLowerCase())
                  .map(({ name, uid }: { name: string; uid: string }) => ({
                    label: name,
                    value: uid,
                  }))
                  .value(),
              )
            }
            if (schema.dataType === BotMDDataType.Clinic) {
              setDropdownOptions(
                chain(clinics)
                  .sortBy(({ name }: { name: string }) => name.toLowerCase())
                  .map(
                    ({ name, domain }: { name: string; domain: string }) => ({
                      label: name,
                      value: domain,
                    }),
                  )
                  .value(),
              )
            }
            setIsLoadingGQL(false)
          },
        )
        .catch((error) => {
          console.error(error)
        })
    }
  }, [settings, dropdownOptions, schema.dataType])

  return (
    <FieldContainer schema={schema} {...fieldContainerProps}>
      <Controller
        control={control}
        rules={rules}
        name={schema._id}
        defaultValue=""
        render={({ field }) => (
          <SingleSelect
            colorScheme={`theme-${colorTheme}`}
            items={dropdownOptions}
            isDisabled={isLoadingGQL}
            {...field}
          />
        )}
      />
    </FieldContainer>
  )
}
