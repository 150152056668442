import { useEffect, useRef, useState } from 'react'
import { Box, forwardRef, Stack, Text } from '@chakra-ui/react'
import { parseNumber } from 'libphonenumber-js'
import { isEmpty, isNil, map, omitBy, range, snakeCase } from 'lodash'
import moment from 'moment'

import {
  ReminderAction,
  ReminderFrequency,
  ReminderMode,
  ReminderTrigger,
  ReminderTriggerUnit,
} from '~shared/types'

import { FieldColorScheme } from '~theme/foundations/colours'
import { Calendar } from '~components/Calendar'
import { SingleSelect } from '~components/Dropdown'
import {
  ReminderActionType,
  ReminderDayOfWeekType,
  ReminderEventType,
  ReminderFieldSchema,
  ReminderFieldValue,
  ReminderUnitType,
} from '~templates/Field/types'

import { DaysInWeek, RecurringReminder } from './RecurringReminder'

export interface ReminderProps {
  /**
   * Whether Reminder component is disabled.
   */
  isDisabled?: boolean
  /**
   * Function called once a reminder is updated
   * @param nextValue the value of the reminder
   */
  onChange?: (nextValue: ReminderFieldValue) => void
  /**
   * The value of the reminder to be `checked`
   * (in controlled mode)
   */
  value?: ReminderFieldValue
  /**
   * The value of the reminder to be `checked`
   * initially (in uncontrolled mode)
   */
  defaultValue?: ReminderFieldValue
  /**
   * The `name` attribute forwarded to each `reminder` element
   */
  name: string
  /**
   * schema of the component to render.
   */
  schema: ReminderFieldSchema
  /**
   * Color scheme of the component to render. Defaults to `primary`.
   */
  colorScheme?: FieldColorScheme
}

export const Reminder = forwardRef<ReminderProps, 'input'>(
  ({ schema, colorScheme, onChange, ...props }, ref) => {
    const initialFocusRef = useRef<HTMLInputElement>(null)
    const reminderOptions = schema.reminderOptions

    const [reminderTime, setReminderTime] = useState('00:00')
    const [startTriggerTime, setStartTriggerTime] = useState('00:00')
    const [endTriggerTime, setEndTriggerTime] = useState('00:00')
    const [startTriggerDate, setStartTriggerDate] = useState<Date | null>(null)
    const [endTriggerDate, setEndTriggerDate] = useState<Date | null>(null)
    const [selectedDay, setSelectedDay] = useState<string[]>([])

    const getFrequencyUnit = (frequency?: ReminderFrequency) => {
      if (frequency === ReminderFrequency.Weekly) {
        return ReminderUnitType.Week
      }
      if (frequency === ReminderFrequency.Monthly) {
        return ReminderUnitType.Month
      }
      if (frequency === ReminderFrequency.Daily) {
        return ReminderUnitType.Day
      }
      return undefined
    }

    const getTriggerUnit = (triggerUnit?: ReminderTriggerUnit) => {
      if (triggerUnit === ReminderTriggerUnit.Month) {
        return ReminderUnitType.Month
      }
      if (triggerUnit === ReminderTriggerUnit.Week) {
        return ReminderUnitType.Week
      }
      if (triggerUnit === ReminderTriggerUnit.Day) {
        return ReminderUnitType.Day
      }
      return undefined
    }
    const getTriggerEvent = (triggerEvent?: ReminderTrigger) => {
      if (triggerEvent === ReminderTrigger.Enrollment) {
        return ReminderEventType.Enrollment
      }
      if (triggerEvent === ReminderTrigger.Exact) {
        return ReminderEventType.Exact
      }
      if (triggerEvent === ReminderTrigger.Discharge) {
        return ReminderEventType.Discharge
      }
      if (triggerEvent === ReminderTrigger.Never) {
        return ReminderEventType.Never
      }
      if (triggerEvent === ReminderTrigger.ProgramEnrollment) {
        return ReminderEventType.ProgramEnrollment
      }
      if (triggerEvent === ReminderTrigger.ProgramDischarge) {
        return ReminderEventType.ProgramDischarge
      }
      return undefined
    }

    const getAction = (action: ReminderAction) => {
      if (action === ReminderAction.Discharge) {
        return ReminderActionType.Discharge
      }
      if (action === ReminderAction.SendReminder) {
        return ReminderActionType.SendReminder
      }
      return ReminderActionType.SendTemplate
    }

    const getDay = (day: string) => {
      if (day === DaysInWeek.Monday) {
        return ReminderDayOfWeekType.Mon
      }
      if (day === DaysInWeek.Tuesday) {
        return ReminderDayOfWeekType.Tue
      }
      if (day === DaysInWeek.Wednesday) {
        return ReminderDayOfWeekType.Wed
      }
      if (day === DaysInWeek.Thursday) {
        return ReminderDayOfWeekType.Thu
      }
      if (day === DaysInWeek.Friday) {
        return ReminderDayOfWeekType.Fri
      }
      if (day === DaysInWeek.Saturday) {
        return ReminderDayOfWeekType.Sat
      }
      if (day === DaysInWeek.Sunday) {
        return ReminderDayOfWeekType.Sun
      }
      return undefined
    }

    useEffect(() => {
      const purpose = {
        purpose: snakeCase(reminderOptions.messageTemplatePurpose),
        template_set: reminderOptions.messageTemplate,
      }
      const newReminder = {
        schedule: {
          action: {
            type: getAction(reminderOptions.action),
            [snakeCase(reminderOptions.action)]:
              reminderOptions.action !== ReminderAction.SendTemplate
                ? {}
                : purpose,
          },
          ...omitBy(
            {
              start_trigger: omitBy(
                {
                  event: getTriggerEvent(reminderOptions.startTrigger),
                  time_of_day: reminderOptions.startTriggerTime,
                  duration: reminderOptions.startTriggerDuration,
                  unit: getTriggerUnit(reminderOptions.startTriggerUnit),
                  is_after: reminderOptions.startTriggerIsAfter,
                  exact_trigger_on: startTriggerDate
                    ? moment(startTriggerDate).format()
                    : null,
                },
                isNil,
              ),
              end_trigger: omitBy(
                {
                  event: getTriggerEvent(reminderOptions.endTrigger),
                  time_of_day: reminderOptions.endTriggerTime,
                  duration: reminderOptions.endTriggerDuration,
                  unit: getTriggerUnit(reminderOptions.endTriggerUnit),
                  is_after: reminderOptions.endTriggerIsAfter,
                  exact_trigger_on: endTriggerDate
                    ? moment(endTriggerDate).format()
                    : null,
                },
                isNil,
              ),
              recurring_frequencies: map(selectedDay, (day) =>
                omitBy(
                  {
                    every: reminderOptions.frequencyDuration,
                    time_of_day: reminderTime,
                    unit: getFrequencyUnit(reminderOptions.frequency),
                    day_of_week: getDay(day),
                    day_of_month:
                      reminderOptions.frequency === ReminderFrequency.Monthly
                        ? parseInt(day)
                        : undefined,
                  },
                  isNil,
                ),
              ),
            },
            (v, k) => k !== 'action' && isEmpty(v),
          ),
        },
      }

      onChange?.(newReminder)
    }, [
      reminderTime,
      selectedDay,
      startTriggerDate,
      endTriggerDate,
      reminderOptions.messageTemplate,
      reminderOptions.action,
      reminderOptions.startTrigger,
      reminderOptions.startTriggerDuration,
      reminderOptions.startTriggerUnit,
      reminderOptions.startTriggerIsAfter,
      reminderOptions.endTrigger,
      reminderOptions.endTriggerDuration,
      reminderOptions.endTriggerUnit,
      reminderOptions.endTriggerIsAfter,
      reminderOptions.frequencyDuration,
      reminderOptions.frequency,
      onChange,
      reminderOptions.startTriggerTime,
      reminderOptions.endTriggerTime,
    ])

    return (
      <>
        {reminderOptions.mode === ReminderMode.Recurring && (
          <>
            <RecurringReminder
              colorScheme={colorScheme}
              mode={reminderOptions.mode}
              frequency={reminderOptions.frequency}
              maxDaySelection={reminderOptions.maxDaySelection}
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
            />
            <Text fontSize="medium" pb={1}>
              Select Time
            </Text>
            <Stack direction="row" spacing={4} align="center" pb={4}>
              <SingleSelect
                name="hour"
                value={reminderTime.split(':')[0]}
                isClearable={false}
                placeholder="Select Hour"
                items={range(24).map((h) => String(h).padStart(2, '0'))}
                onChange={(data) =>
                  setReminderTime(`${data}:${reminderTime.split(':')[1]}`)
                }
              />
              <SingleSelect
                name="minute"
                value={reminderTime.split(':')[1]}
                isClearable={false}
                placeholder="Select Minute"
                items={range(60).map((m) => String(m).padStart(2, '0'))}
                onChange={(data) =>
                  setReminderTime(`${reminderTime.split(':')[0]}:${data}`)
                }
              />
            </Stack>
          </>
        )}
        {reminderOptions.startTrigger === ReminderTrigger.Exact && (
          <>
            <Text fontSize="medium" pb={1}>
              Start Trigger
            </Text>
            <Box
              as="div"
              bgColor={'theme-grey.100'}
              width={'fit-content'}
              p={1}
              pb={2}
              m={1}
            >
              <Calendar
                value={startTriggerDate}
                monthsToDisplay={1}
                colorScheme={colorScheme}
                onChange={(date) => setStartTriggerDate(date)}
                ref={initialFocusRef}
              />
            </Box>
            {!reminderOptions.startTriggerTime && (
              <>
                <Text fontSize="medium" pb={1}>
                  Select Time
                </Text>
                <Stack direction="row" spacing={4} align="center" pb={4}>
                  <SingleSelect
                    name="start-hour"
                    value={startTriggerTime.split(':')[0]}
                    isClearable={false}
                    placeholder="Select Hour"
                    items={range(24).map((h) => String(h).padStart(2, '0'))}
                    onChange={(data) => {
                      const newDate = startTriggerDate || new Date()
                      newDate.setHours(parseInt(data))

                      setStartTriggerDate(newDate)
                      setStartTriggerTime(
                        `${data}:${startTriggerTime.split(':')[1]}`,
                      )
                    }}
                  />
                  <SingleSelect
                    name="start-minute"
                    value={startTriggerTime.split(':')[1]}
                    isClearable={false}
                    placeholder="Select Minute"
                    items={range(60).map((m) => String(m).padStart(2, '0'))}
                    onChange={(data) => {
                      const newDate = startTriggerDate || new Date()
                      newDate.setMinutes(parseInt(data), 0)

                      setStartTriggerDate(newDate)
                      setStartTriggerTime(
                        `${startTriggerTime.split(':')[0]}:${data}`,
                      )
                    }}
                  />
                </Stack>
              </>
            )}
          </>
        )}
        {reminderOptions.endTrigger === ReminderTrigger.Exact && (
          <>
            <Text fontSize="medium" pb={1}>
              End Trigger
            </Text>
            <Box
              as="div"
              bgColor={'white'}
              width={'fit-content'}
              p={1}
              pb={2}
              m={1}
            >
              <Calendar
                value={endTriggerDate}
                monthsToDisplay={1}
                colorScheme={colorScheme}
                onChange={(date) => setEndTriggerDate(date)}
                ref={initialFocusRef}
              />
            </Box>
            {!reminderOptions.endTriggerTime && (
              <>
                <Text fontSize="medium" pb={1}>
                  Select Time
                </Text>
                <Stack direction="row" spacing={4} align="center" pb={4}>
                  <SingleSelect
                    name="end-hour"
                    value={endTriggerTime.split(':')[0]}
                    isClearable={false}
                    placeholder="Select Hour"
                    items={range(24).map((h) => String(h).padStart(2, '0'))}
                    onChange={(data) => {
                      const newDate = endTriggerDate || new Date()
                      newDate.setHours(parseInt(data))

                      setEndTriggerDate(newDate)
                      setEndTriggerTime(
                        `${data}:${endTriggerTime.split(':')[1]}`,
                      )
                    }}
                  />
                  <SingleSelect
                    name="end-minute"
                    value={endTriggerTime.split(':')[1]}
                    isClearable={false}
                    placeholder="Select Minute"
                    items={range(60).map((m) => String(m).padStart(2, '0'))}
                    onChange={(data) => {
                      const newDate = endTriggerDate || new Date()
                      newDate.setMinutes(parseInt(data), 0)

                      setEndTriggerDate(newDate)
                      setEndTriggerTime(
                        `${endTriggerTime.split(':')[0]}:${data}`,
                      )
                    }}
                  />
                </Stack>
              </>
            )}
          </>
        )}
      </>
    )
  },
)
