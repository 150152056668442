import { OgpLogoFull } from '~assets/svgrs/brand/OgpFullLogo'

import { FooterLinkWithIcon } from './types'

export const DEFAULT_FOOTER_ICON_LINK: FooterLinkWithIcon = {
  href: 'https://open.gov.sg',
  label: 'Open Government Products homepage',
  Icon: OgpLogoFull,
}

export const DEFAULT_SOCIAL_MEDIA_LINKS: FooterLinkWithIcon[] = []
