import { chakra } from '@chakra-ui/react'

export const MobileOtpSvgr = chakra(
  (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
    return (
      <svg
        width={125}
        height={174}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          x={2.677}
          y={1}
          width={93.713}
          height={172}
          rx={16.816}
          fill="#C9CCCF"
        />
        <rect
          x={2.677}
          y={1}
          width={93.713}
          height={172}
          rx={16.816}
          stroke="#000"
          strokeWidth={0.75}
        />
        <path
          d="M79.63 4.23h-9.816a2.735 2.735 0 0 0-2.48 1.58l-.338.73a2.737 2.737 0 0 1-2.478 1.58H34.553a2.737 2.737 0 0 1-2.48-1.58l-.338-.73a2.732 2.732 0 0 0-2.478-1.58h-9.805a13.907 13.907 0 0 0-13.91 13.907v137.725a13.909 13.909 0 0 0 13.91 13.907H79.63c7.68 0 13.907-6.227 13.907-13.907V18.137c0-7.681-6.226-13.908-13.907-13.908Z"
          fill="#fff"
        />
        <path
          d="M96.39 25.922h1.678v21.361h-1.677V25.922ZM96.39 52.112h1.678v21.362h-1.677V52.112ZM1 36.605h1.677v21.361H1V36.605Z"
          fill="#000"
          stroke="#000"
          strokeWidth={0.259}
        />
        <rect
          x={27.418}
          y={46.497}
          width={96.733}
          height={65.872}
          rx={9.411}
          fill="#4A61C0"
        />
        <rect
          x={27.418}
          y={46.497}
          width={96.733}
          height={65.872}
          rx={9.411}
          stroke="#000"
          strokeWidth={0.75}
        />
        <path d="m46.69 106.923-6.045 12.967 16.078-9.033" fill="#4A61C0" />
        <path
          d="m44.413 111.851-3.768 8.039 14.004-7.829"
          stroke="#000"
          strokeWidth={0.75}
        />
        <path
          d="M40.238 86h2.061l-.18-4.03 3.382 2.187 1.036-1.814-3.592-1.843 3.592-1.843-1.036-1.814-3.382 2.186.18-4.029h-2.061l.18 4.03-3.382-2.187L36 78.657l3.592 1.843L36 82.343l1.036 1.814 3.382-2.186-.18 4.029ZM54.19 86h2.062l-.18-4.03 3.382 2.187 1.035-1.814-3.591-1.843 3.591-1.843-1.035-1.814-3.383 2.186.181-4.029H54.19l.18 4.03-3.382-2.187-1.035 1.814 3.591 1.843-3.591 1.843 1.035 1.814 3.383-2.186L54.19 86ZM68.143 86h2.062l-.18-4.03 3.381 2.187 1.036-1.814-3.591-1.843 3.591-1.843-1.035-1.814-3.383 2.186.18-4.029h-2.061l.18 4.03-3.382-2.187-1.036 1.814 3.592 1.843-3.592 1.843 1.036 1.814 3.382-2.186-.18 4.029ZM82.095 86h2.062l-.18-4.03 3.382 2.187 1.036-1.814-3.592-1.843 3.592-1.843-1.036-1.814-3.382 2.186.18-4.029h-2.062l.18 4.03-3.382-2.187-1.035 1.814 3.591 1.843-3.591 1.843 1.035 1.814 3.383-2.186-.18 4.029ZM96.048 86h2.062l-.18-4.03 3.382 2.187 1.035-1.814-3.591-1.843 3.591-1.843-1.035-1.814-3.383 2.186.18-4.029h-2.061l.18 4.03-3.382-2.187-1.035 1.814 3.591 1.843-3.591 1.843 1.035 1.814 3.383-2.186L96.048 86ZM110.001 86h2.061l-.18-4.03 3.382 2.187 1.036-1.814-3.591-1.843 3.591-1.843-1.036-1.814-3.382 2.186.18-4.029h-2.061l.18 4.03-3.382-2.187-1.036 1.814 3.592 1.843-3.592 1.843 1.036 1.814 3.382-2.186-.18 4.029Z"
          fill="#8998D6"
        />
      </svg>
    )
  },
)
