import { PrefillMap } from '~features/public-form/components/FormFields/FormFields'

import { BaseFieldProps, FieldContainer } from '../FieldContainer'
import { MobileFieldSchema } from '../types'

import { MobileFieldInput } from './MobileFieldInput'

export interface MobileFieldProps extends BaseFieldProps {
  schema: MobileFieldSchema
  disableRequiredValidation?: boolean
  prefill?: PrefillMap[string]
}

export const MobileField = ({
  schema,
  disableRequiredValidation,
  ...fieldContainerProps
}: MobileFieldProps): JSX.Element => {
  const isPrefilled = !!fieldContainerProps?.prefill?.prefillValue

  // Prefill can be locked only if there is a prefill value
  // If prefill is enabled but no prefill value is provided, it is not locked
  const isPrefillLocked =
    isPrefilled && !!fieldContainerProps?.prefill?.lockPrefill

  return (
    <FieldContainer schema={schema} {...fieldContainerProps}>
      <MobileFieldInput
        schema={schema}
        disableRequiredValidation={disableRequiredValidation}
        prefill={
          schema.allowIntlNumbers && schema.allowPrefill
            ? fieldContainerProps?.prefill?.prefillValue
            : undefined
        }
        isPrefilled={
          schema.allowIntlNumbers && schema.allowPrefill ? isPrefilled : false
        }
        isPrefillLocked={
          schema.allowIntlNumbers && schema.lockPrefill
            ? isPrefillLocked
            : false
        }
      />
    </FieldContainer>
  )
}
