import { SVGProps } from 'react'

export const EmailResponsesSvgr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={196}
    height={146}
    viewBox="0 0 196 146"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M185.965 64.713H45.178l-16.915 80.264h140.788l16.914-80.264Z"
      fill="#E4E7F6"
      stroke="#293044"
      strokeWidth={0.5}
    />
    <path
      d="M165.809.75h-.25v143.196h3.515V.75h-3.265Z"
      fill="#293044"
      stroke="#293044"
      strokeWidth={0.5}
    />
    <path
      d="M53.26.75h-.25v143.196H166.06V.75H53.26Z"
      fill="#fff"
      stroke="#293044"
      strokeWidth={0.5}
    />
    <path d="M52.99 15.229H165.75v41.973H52.991V15.228Z" fill="#4A61C0" />
    <path
      d="M69.938 29.485h78.837v1.14H69.937v-1.14ZM69.938 35.67h78.837v1.14H69.937v-1.14ZM85.701 41.86h47.793V43H85.701v-1.14Z"
      fill="#8998D6"
    />
    <circle
      cx={112}
      cy={55.19}
      r={19.683}
      transform="rotate(45 112 55.19)"
      fill="#05CC9A"
      stroke="#293044"
      strokeWidth={0.5}
    />
    <path
      d="m158.827 79.753-11.266 57.474L166.79 145h2.266l8.548-40.565-18.777-24.682Z"
      fill="#C9CCCF"
    />
    <path
      d="m158.827 79.753-11.266 57.474L166.79 145h2.266l5.084-24.125-15.313-41.122Z"
      fill="#293044"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.847 79.754H80.929l-5.44-9.888h-57.4L28.316 145h140.739l-10.229-65.246h-58.98Z"
      fill="#F6F7FC"
      stroke="#293044"
      strokeWidth={0.5}
    />
    <path
      d="M102.203 112.377h-57.4M103.455 120.713H46.058M71.662 129.047H47.31"
      stroke="#293044"
      strokeWidth={0.5}
    />
    <path
      d="M44.878 27.48v-.7a.673.673 0 0 0-.656-.657.673.673 0 0 0-.657.656v.7c0 .351-.307.658-.657.658h-.657a.673.673 0 0 0-.656.656c0 .35.306.657.656.657h.7c.351 0 .658.307.658.657v.7c0 .35.306.657.656.657.35 0 .657-.306.657-.657v-.7c0-.35.307-.657.657-.657h.7c.35 0 .657-.306.657-.657a.673.673 0 0 0-.657-.656h-.7c-.35 0-.657-.263-.7-.657ZM2.145 43.417v-.438c0-.219-.175-.438-.394-.438-.219 0-.437.176-.437.395v.481a.439.439 0 0 1-.438.438H.438a.439.439 0 0 0-.438.438c0 .219.175.438.394.438h.438c.219 0 .438.175.438.394v.438c0 .218.175.437.394.437a.439.439 0 0 0 .438-.437v-.438c0-.22.175-.394.394-.438h.438a.439.439 0 0 0 0-.876h-.438c-.175 0-.35-.175-.35-.394Zm8.538 19.265v-.35a.3.3 0 0 0-.306-.307.3.3 0 0 0-.307.307v.35a.3.3 0 0 1-.306.306H9.37a.3.3 0 0 0-.307.307.3.3 0 0 0 .307.306h.35a.3.3 0 0 1 .306.307v.35c0 .175.132.35.307.35s.35-.13.35-.306V63.908c0-.175.132-.35.307-.35h.35a.3.3 0 0 0 .306-.307c0-.175-.13-.35-.306-.35h-.306c-.176.131-.35-.044-.35-.219Zm183.544-13.88v-.35a.299.299 0 0 0-.306-.306.299.299 0 0 0-.306.306v.35a.3.3 0 0 1-.307.307h-.35c-.175 0-.351.131-.351.306 0 .176.132.35.307.35H193.308c.175 0 .35.132.35.307v.35a.3.3 0 0 0 .307.307c.175 0 .35-.131.35-.306v-.35a.3.3 0 0 1 .307-.307h.35a.299.299 0 0 0 .306-.307.299.299 0 0 0-.306-.306h-.35c-.263 0-.395-.175-.395-.35ZM34.239 42.718c0 .35-.306.656-.657.656a.673.673 0 0 1-.657-.656c0-.35.307-.657.657-.657.394 0 .657.306.657.657ZM19.79 33.74a.878.878 0 0 1-.875.876.878.878 0 0 1-.876-.876c0-.482.394-.876.876-.876.481 0 .875.394.875.876Zm168.789-.088c.482 0 .876.394.876.876a.878.878 0 0 1-.876.876.878.878 0 0 1-.875-.876c0-.482.394-.876.875-.876Z"
      fill="#445072"
    />
    <path
      d="M117.7 50h-9.1c-.717 0-1.3.583-1.3 1.3v3.25h1.3V52.6l4.16 3.12a.648.648 0 0 0 .78 0l4.16-3.12v5.85h-5.2v1.3h5.2c.717 0 1.3-.583 1.3-1.3V51.3c0-.717-.583-1.3-1.3-1.3Zm-4.55 4.388-4.117-3.088h8.234l-4.117 3.088Z"
      fill="#293044"
    />
    <path
      d="M106 55.2h4.55v1.3H106v-1.3Zm1.3 1.95h3.9v1.3h-3.9v-1.3Zm1.95 1.95h2.6v1.3h-2.6v-1.3Z"
      fill="#293044"
    />
  </svg>
)
