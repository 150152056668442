import { Stack } from '@chakra-ui/react'

import { BotMDCleoSessionInput } from './BotMDCleoSessionInput'
import { BotMDGQLUrlInput } from './BotMDGQLUrlInput'

export const BotMDSettingsSection = (): JSX.Element => {
  return (
    <Stack spacing="2.5rem">
      <BotMDGQLUrlInput />
      <BotMDCleoSessionInput />
    </Stack>
  )
}
