import Translation from './types'

export const enSG: Translation = {
  translation: {
    features: {
      login: {
        LoginPage: {
          slogan: 'Build secure forms in minutes',
        },
        components: {
          LoginForm: {
            onlyAvailableForPublicOfficers:
              'Log in with a whitelisted email address. Contact customersupport@botmd.io for more information.',
            emailEmptyErrorMsg: 'Please enter an email address',
            login: 'Log in',
            haveAQuestion: 'Have a question?',
          },
        },
      },
    },
  },
}
