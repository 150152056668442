/**
 * @precondition Must have a parent `react-hook-form#FormProvider` component.
 */
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { createNricValidationRules } from '~utils/fieldValidation'
import Input from '~components/Input'

import { PrefillMap } from '~features/public-form/components/FormFields/FormFields'

import { BaseFieldProps, FieldContainer } from '../FieldContainer'
import { NricFieldSchema, SingleAnswerFieldInput } from '../types'

export interface NricFieldProps extends BaseFieldProps {
  schema: NricFieldSchema
  disableRequiredValidation?: boolean
  prefill?: PrefillMap[string]
}

export const NricField = ({
  schema,
  disableRequiredValidation,
  ...fieldContainerProps
}: NricFieldProps): JSX.Element => {
  const validationRules = useMemo(
    () => createNricValidationRules(schema, disableRequiredValidation),
    [schema, disableRequiredValidation],
  )

  const { register, setValue } = useFormContext<SingleAnswerFieldInput>()

  const isPrefilled = !!fieldContainerProps?.prefill?.prefillValue

  // Prefill can be locked only if there is a prefill value
  // If prefill is enabled but no prefill value is provided, it is not locked
  const isPrefillLocked =
    isPrefilled && !!fieldContainerProps?.prefill?.lockPrefill

  return (
    <FieldContainer schema={schema}>
      <Input
        aria-label={`${schema.questionNumber}. ${schema.title}`}
        defaultValue={
          schema.allowPrefill ? fieldContainerProps?.prefill?.prefillValue : ''
        }
        isPrefilled={schema.allowPrefill ? isPrefilled : false}
        isPrefillLocked={schema.lockPrefill ? isPrefillLocked : false}
        preventDefaultOnEnter
        {...register(schema._id, {
          ...validationRules,
          onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
            setValue(schema._id, event.target.value.trim()),
        })}
      />
    </FieldContainer>
  )
}
