import { Box, Divider, Flex, Link, Stack, Text, Wrap } from '@chakra-ui/react'

import {
  DEFAULT_FOOTER_ICON_LINK,
  DEFAULT_SOCIAL_MEDIA_LINKS,
} from './common/constants'
import { FooterContainerProps, FooterVariantProps } from './common/types'

export const FullFooter = ({
  appName,
  appLink,
  tagline,
  footerLinks,
  footerIconLink = DEFAULT_FOOTER_ICON_LINK,
  socialMediaLinks = DEFAULT_SOCIAL_MEDIA_LINKS,
  textColorScheme = 'secondary',
  containerProps,
}: FooterVariantProps): JSX.Element => {
  const currentYear = new Date().getFullYear()

  return (
    <FullFooter.Container {...containerProps}>
      <Divider my="1.5rem" />
      <FullFooter.Section>
        <Box>
          <Text
            textStyle="caption-1"
            color={`${textColorScheme}.500`}
            mb="0.5rem"
          >
            Built by
          </Text>
          <Link
            isExternal
            title={footerIconLink.label}
            colorScheme={textColorScheme}
            mb="2rem"
            href={footerIconLink.href}
          >
            <Text textStyle="legal" ml={2} color={`${textColorScheme}.500`}>
              ©{currentYear} Open Government Products
            </Text>
          </Link>
        </Box>
      </FullFooter.Section>
    </FullFooter.Container>
  )
}

FullFooter.Container = ({
  children,
  ...props
}: FooterContainerProps): JSX.Element => {
  return (
    <Flex
      as="footer"
      flexDirection="column"
      pb="3rem"
      px={{ base: '1.5rem', md: '5.5rem', lg: '9.25rem' }}
      {...props}
    >
      {children}
    </Flex>
  )
}

FullFooter.Section = ({
  children,
  ...props
}: FooterContainerProps): JSX.Element => {
  return (
    <Flex
      align={{ base: 'normal', lg: 'center' }}
      flex={1}
      justify="space-between"
      flexDir={{ base: 'column', lg: 'row' }}
      {...props}
    >
      {children}
    </Flex>
  )
}
