import { useEffect, useMemo, useRef } from 'react'
import {
  FormControl,
  InputGroup,
  InputRightElement,
  useMergeRefs,
} from '@chakra-ui/react'
import { extend, pick } from 'lodash'

import { HomenoFieldBase } from '~shared/types/field'

import { GUIDE_PREFILL } from '~constants/links'
import { createBaseValidationRules } from '~utils/fieldValidation'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Input from '~components/Input'
import Textarea from '~components/Textarea'
import Toggle from '~components/Toggle'
import { CopyButton } from '~templates/CopyButton'

import { CreatePageDrawerContentContainer } from '../../../../../common'
import { FormFieldDrawerActions } from '../common/FormFieldDrawerActions'
import { EditFieldProps } from '../common/types'
import { useEditFieldForm } from '../common/useEditFieldForm'

const EDIT_HOMENO_KEYS = [
  'title',
  'description',
  'required',
  'allowIntlNumbers',
  'defaultCountry',
  'allowPrefill',
  'lockPrefill',
] as const

type EditHomenoProps = EditFieldProps<HomenoFieldBase>

type EditHomenoInputs = Pick<HomenoFieldBase, typeof EDIT_HOMENO_KEYS[number]>

export const EditHomeno = ({ field }: EditHomenoProps): JSX.Element => {
  const {
    watch,
    register,
    formState: { errors },
    buttonText,
    handleUpdateField,
    isLoading,
    handleCancel,
  } = useEditFieldForm<EditHomenoInputs, HomenoFieldBase>({
    field,
    transform: {
      input: (inputField) => pick(inputField, EDIT_HOMENO_KEYS),
      output: (formOutput, originalField) =>
        extend({}, originalField, formOutput),
    },
  })

  const requiredValidationRule = useMemo(
    () => createBaseValidationRules({ required: true }),
    [],
  )

  const hasLockPrefillRef = useRef<HTMLInputElement>(null)
  const hasInternationalRef = useRef<HTMLInputElement>(null)

  const allowPrefillRegister = useMemo(
    () => register('allowPrefill'),
    [register],
  )
  const lockPrefillRegister = useMemo(() => register('lockPrefill'), [register])

  const mergedAllowPrefillRef = useMergeRefs(
    hasInternationalRef,
    allowPrefillRegister.ref,
  )

  const mergedLockPrefillRef = useMergeRefs(
    hasLockPrefillRef,
    lockPrefillRegister.ref,
  )

  const watchAllowPrefill = watch('allowPrefill')
  const watchLockPrefill = watch('lockPrefill')
  const watchLockAllowInternational = watch('allowIntlNumbers')

  useEffect(() => {
    // Prefill must be enabled for lockPrefill
    // We cannot simply use setValue as it does not update
    // the UI
    if (!watchAllowPrefill && watchLockPrefill) {
      hasLockPrefillRef.current?.click()
    }
    if (!watchLockAllowInternational) {
      hasInternationalRef.current?.click()
    }
  }, [watchAllowPrefill, watchLockPrefill, watchLockAllowInternational])

  return (
    <CreatePageDrawerContentContainer>
      <FormControl isRequired isReadOnly={isLoading} isInvalid={!!errors.title}>
        <FormLabel>Question</FormLabel>
        <Input autoFocus {...register('title', requiredValidationRule)} />
        <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired
        isReadOnly={isLoading}
        isInvalid={!!errors.description}
      >
        <FormLabel>Description</FormLabel>
        <Textarea {...register('description')} />
        <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isReadOnly={isLoading}>
        <Toggle {...register('required')} label="Required" />
      </FormControl>
      <FormControl isRequired isReadOnly={isLoading}>
        <FormLabel>Default Country</FormLabel>
        <Input {...register('defaultCountry')} />
      </FormControl>
      <FormControl isReadOnly={isLoading}>
        <Toggle
          {...register('allowIntlNumbers')}
          label="Allow international numbers"
        />
      </FormControl>
      <FormControl isReadOnly={isLoading}>
        <Toggle
          {...allowPrefillRegister}
          label="Enable pre-fill"
          ref={mergedAllowPrefillRef}
          description={`Use Field ID in the form URL to pre-fill this field for respondents. [Learn how](${GUIDE_PREFILL})`}
          isDisabled={!watchLockAllowInternational}
        />
        {watchAllowPrefill ? (
          <>
            <InputGroup mt="0.5rem">
              <Input
                isReadOnly
                isDisabled={!field._id}
                value={
                  field._id ??
                  'Field ID will be generated after this field is saved'
                }
              />
              {field._id ? (
                <InputRightElement>
                  <CopyButton
                    stringToCopy={field._id}
                    aria-label="Copy field ID value"
                  />
                </InputRightElement>
              ) : null}
            </InputGroup>
          </>
        ) : null}
      </FormControl>
      <FormControl isReadOnly={isLoading}>
        <Toggle
          {...lockPrefillRegister}
          ref={mergedLockPrefillRef}
          label="Prevent pre-fill editing"
          description="This prevents respondents from clicking the field to edit it. However, field content can still be modified via the URL."
          isDisabled={!watchAllowPrefill || !watchLockAllowInternational}
        />
      </FormControl>
      <FormFieldDrawerActions
        isLoading={isLoading}
        buttonText={buttonText}
        handleClick={handleUpdateField}
        handleCancel={handleCancel}
      />
    </CreatePageDrawerContentContainer>
  )
}
