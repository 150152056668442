// icon:bx-menu-alt-left | Boxicons https://boxicons.com/ | Atisa

export const BxMenuAltLeft = (
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M4 11h12v2H4zm0-5h16v2H4zm0 12h7.235v-2H4z" />
    </svg>
  )
}
