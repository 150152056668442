import { BasicField, FieldBase } from './base'

export enum ReminderMode {
  OneTime = 'OneTime',
  Recurring = 'Recurring',
}

export enum ReminderFrequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  // Yearly = 'Yearly',
}

export enum ReminderAction {
  SendTemplate = 'SendTemplate',
  SendReminder = 'SendReminder',
  Discharge = 'Discharge',
}

export enum ReminderTrigger {
  Never = 'Never',
  Enrollment = 'Enrollment',
  Discharge = 'Discharge',
  Exact = 'Exact',
  ProgramEnrollment = 'Program Enrollment',
  ProgramDischarge = 'Program Discharge',
  // ClinicalParameter = 'ClinicalParameter',
}

export enum ReminderTriggerUnit {
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  // Year = 'year',
}

export enum MessageTemplatePurpose {
  Reminder = 'reminder',
  UserDefined = 'user_defined',
}

export interface ReminderFieldBase extends FieldBase {
  fieldType: BasicField.Reminder
  reminderOptions: {
    mode: ReminderMode
    action: ReminderAction
    startTrigger?: ReminderTrigger
    endTrigger?: ReminderTrigger
    frequency?: ReminderFrequency
    messageTemplate?: string
    frequencyDuration?: number
    startTriggerDuration?: number
    startTriggerTime?: string
    startTriggerUnit?: ReminderTriggerUnit
    startTriggerIsAfter?: boolean
    endTriggerDuration?: number
    endTriggerTime?: string
    endTriggerUnit?: ReminderTriggerUnit
    endTriggerIsAfter?: boolean
    maxDaySelection?: number
    messageTemplatePurpose?: MessageTemplatePurpose
  }
}
